body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Assaigning the variable */

:root{
  --mainWhite: #f9fbfc;
  --mainBlue: #1A4668;
  --lightBlue: #a2cdb8;
  --mainRed: #e1755e;
  --mainDark: #5d554b;
}
/* since there is bootstraps in App.js, we have set font as important so, this css will get presedence */
body{
 font-family: 'Oswald', sans-serif !important;
 background: #f9fbfc !important;
 background: var(--mainWhite) !important;
 color:#5d554b !important;
 color:var(--mainDark) !important;
}
.text-title{
  font-family: 'Roboto Slab', serif;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}
/* used in Title */
.text-blue{
  color:#1A4668;
  color:var(--mainBlue);
}

.text-bright{
  color: #a2cdb8;
  color: var(--lightBlue)
}

